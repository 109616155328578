import type { MenuItem } from '@landing-blocks/shared/types/menu.js';
import type { FunctionalComponent } from 'preact';
import { useCallback, useRef, useState } from 'preact/hooks';

import { Sidebar } from '../sidebar/sidebar.js';
import { BurgerCloseIcon } from './__close-icon/burger__close-icon.js';
import { BurgerIcon } from './__icon/burger__icon.js';
import { cnBurger } from './burger.constants.js';

import './burger.css';

export interface BurgerProps {
    menuItems: MenuItem[];
    profession?: string;
    country: string;
    logInButtonText: string;
}

export const Burger: FunctionalComponent<BurgerProps> = ({ menuItems, country, profession, logInButtonText }) => {
    const [open, setOpen] = useState(false);
    const stickyBurger = useRef<HTMLButtonElement>(null);

    const handleClick = useCallback(() => {
        setOpen((prev) => {
            document.body.style.overflow = prev ? 'auto' : 'hidden';

            return !prev;
        });
    }, []);

    return (
        <div className={cnBurger()}>
            <Sidebar
                open={open}
                menuItems={menuItems}
                country={country}
                profession={profession}
                logInButtonText={logInButtonText}
            />
            <button
                className={cnBurger('button')}
                onClick={handleClick}
                ref={stickyBurger}
                aria-label="Navigation menu"
            >
                {open ? <BurgerCloseIcon /> : <BurgerIcon />}
            </button>
        </div>
    );
};
