import type { ComponentChildren, FunctionalComponent } from 'preact';

import { cnSidebar } from '../sidebar.constants.js';

import './sidebar__body.css';

export interface SidebarBodyProps {
    children?: ComponentChildren;
}

export const SidebarBody: FunctionalComponent<SidebarBodyProps> = ({ children }) => (
    <div className={cnSidebar('body')}>{children}</div>
);
