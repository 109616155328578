import { getLoginUrl } from '@landing-blocks/menus/header/header.utils/get-login-url.js';
import type { MenuItem } from '@landing-blocks/shared/types/menu.js';
import type { FunctionalComponent } from 'preact';

import { SidebarBody } from './__body/sidebar__body.js';
import { SidebarLogin } from './__login/sidebar__login.js';
import { SidebarLogo } from './__logo/sidebar__logo.js';
import { SidebarNavbar } from './__navbar/sidebar__navbar.js';
import { cnSidebar } from './sidebar.constants.js';

import './sidebar.css';

export interface SidebarProps {
    logInButtonText: string;
    open: boolean;
    menuItems: MenuItem[];
    profession?: string;
    country: string;
}

export const Sidebar: FunctionalComponent<SidebarProps> = ({
    open,
    menuItems,
    profession,
    country,
    logInButtonText,
}) => {
    const loginUrl = getLoginUrl(country, profession);

    return (
        <div className={cnSidebar({ open })}>
            <SidebarLogo />
            <SidebarBody>
                <SidebarNavbar menuItems={menuItems} />
                {loginUrl && <SidebarLogin href={loginUrl}>{logInButtonText}</SidebarLogin>}
            </SidebarBody>
        </div>
    );
};
