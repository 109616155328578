import type { MenuItem } from '@landing-blocks/shared/types/menu.js';
import type { FunctionalComponent } from 'preact';

import { cnSidebar } from '../sidebar.constants.js';

import './sidebar__navbar.css';

export interface SidebarNavbarProps {
    menuItems: MenuItem[];
}

export const SidebarNavbar: FunctionalComponent<SidebarNavbarProps> = ({ menuItems }) => (
    <nav className={cnSidebar('navbar')}>
        <ul className={cnSidebar('navbar-section-list')}>
            {menuItems.map(({ id: id, title, url, children = [] }) => (
                <li key={id} className={cnSidebar('navbar-section')}>
                    {children.length ? (
                        <>
                            {title}
                            <ul className={cnSidebar('navbar-subsection-list')}>
                                {children.map(({ id: childItemId, title: childItemTitle, url: childItemUrl }) => (
                                    <li key={childItemId} className={cnSidebar('navbar-subsection')}>
                                        <a className={cnSidebar('navbar-link')} href={childItemUrl}>
                                            {childItemTitle}
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </>
                    ) : (
                        <a className={cnSidebar('navbar-link')} href={url}>
                            {title}
                        </a>
                    )}
                </li>
            ))}
        </ul>
    </nav>
);
